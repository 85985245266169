<template>
  <v-container v-if="preface" id="donation">
    <v-card class="rounded-xl mx-auto mb-4" outlined elevation="0" tile>
      <v-card-title>
        <v-btn @click.stop="$router.push({ name: 'home' })" icon>
          <v-icon large left>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-icon large left>mdi-hand-heart</v-icon>
        <div class="headline">
          {{ isHindi ? "दान कर सहयोग करें। " : "Help Us, Donate!" }}
        </div>
      </v-card-title>
    </v-card>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-card class="rounded-xl" outlined tile>
          <v-card-title>{{
            isHindi ? "कोई भी धन राशि चुनें" : "Choose any amount."
          }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="6"
                class="pa-1"
                v-for="(donation, idx) in donationButtons"
                :key="idx"
              >
                <v-btn
                  :disabled="loading"
                  :class="
                    donationSelected && donationSelected.ident == donation.ident
                      ? 'rounded-xl large-text indigo darken-3 white--text'
                      : 'rounded-xl large-text'
                  "
                  ripple
                  tile
                  block
                  outlined
                  x-large
                  @click.stop="donationSelected = donation"
                >
                  {{ `${donation.text}` }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-text-field
          oninput="if(Number(this.value) > Number(this.max)) {this.value = this.max;} else if(Number(this.value) < Number(this.min)){ this.value = this.min;}"
          :min="0"
          :max="9999"
          @blur="
            () => {
              if (
                !donationSelected ||
                !donationSelected.amount ||
                donationSelected.amount < 1
              ) {
                donationSelected.amount = 5;
              }
            }
          "
          :error-messages="
            !donationSelected ||
            !donationSelected.amount ||
            donationSelected.amount < 5
              ? isHindi
                ? 'दान के लिए न्यूनतम राशि ₹5 है।'
                : 'Minimum amount for donation is ₹5.'
              : null
          "
          v-if="donationSelected && donationSelected.ident == 'amount_other'"
          outlined
          class="mt-10 rounded-xl"
          :label="
            isHindi
              ? '₹5 - ₹9999/- तक की कोई राशि। '
              : 'Any amount between ₹5 - ₹9999/-.'
          "
          v-model="donationSelected.amount"
          prefix="₹"
          x-large
          type="number"
        ></v-text-field>
        <v-btn
          :disabled="
            !donationSelected ||
            !donationSelected.amount ||
            donationSelected.amount < 5
          "
          :loading="loading"
          outlined
          class="mt-4 indigo darken-4 rounded-xl"
          dark
          ripple
          tile
          block
          x-large
          @click.stop="handleFinalDonationClick"
        >
          {{ donationFinalBtnText }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-card-text
          v-html="isHindi ? preface.value_hi : preface.value"
          class="subtitle-1 font-weight-light d-md-none"
        >
          {{ isHindi ? preface.value_hi : preface.value }}
        </v-card-text>
        <v-card-text
          v-html="isHindi ? preface.value_hi : preface.value"
          class="title font-weight-light d-none d-md-flex"
        >
        </v-card-text>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { handleClick } from "../modules/payment";

export default {
  name: "donate",
  components: {},

  data() {
    return {
      preface: null,
      donationSelected: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    donationButtons() {
      return [
        { ident: "amount_1", amount: 9, text: "₹ 9" },
        { ident: "amount_2", amount: 19, text: "₹ 29" },
        { ident: "amount_3", amount: 49, text: "₹ 49" },
        {
          ident: "amount_other",
          amount: 99,
          text: this.isHindi ? "अन्य" : "Other",
        },
        // { amount: 50, text: "50" },
      ];
    },
    donationFinalBtnText() {
      if (this.donationSelected) {
        let { ident, amount, text } = this.donationSelected;
        if (ident == "amount_other") {
          text = `₹ ${amount}`;
        }
        if (!amount || amount < 0) {
          amount = 0;
          text = `₹ ${amount}`;
        }
        return this.isHindi
          ? `मैं ${text} /- का योगदान करूंगा।`
          : `I will donate ${text} /-`;
      } else {
        return this.isHindi ? "कोई भी राशि चुनें" : "choose an amount";
      }
    },
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {
    "donationSelected.amount"(val) {
      // if (val < 10) {
      //   this.donationSelected.amount = 10;
      // } else if (val > 5000) {
      //   this.donationSelected.amount = 5000;
      // }
    },
  },
  methods: {
    handleFinalDonationClick() {
      if (
        this.auth &&
        this.auth.user &&
        this.donationSelected &&
        this.donationSelected.amount
      ) {
        this.loading = true;
        handleClick({
          amount: this.donationSelected.amount * 100,
          user: this.auth.user,
        })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    fetchMeta() {
      bus.$emit("showWait");
      axios
        .get("/globals/donate_preface")
        .then((res) => {
          if (res.data) {
            this.preface = res.data;
          }
        })
        .finally(() => {
          this.donationSelected = this.donationButtons[1];
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchMeta();
  },
};
</script>

<style>
.dontation .v-text-field input {
  font-size: 2rem;
}

.dontation .large-text span {
  font-size: 1.2rem;
}

.dontation .v-text-field input::-webkit-outer-spin-button,
.dontation .v-text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

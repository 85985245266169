var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.preface)?_c('v-container',{attrs:{"id":"donation"}},[_c('v-card',{staticClass:"rounded-xl mx-auto mb-4",attrs:{"outlined":"","elevation":"0","tile":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'home' })}}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-hand-heart")]),_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.isHindi ? "दान कर सहयोग करें। " : "Help Us, Donate!")+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":"","tile":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.isHindi ? "कोई भी धन राशि चुनें" : "Choose any amount."))]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.donationButtons),function(donation,idx){return _c('v-col',{key:idx,staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-btn',{class:_vm.donationSelected && _vm.donationSelected.ident == donation.ident
                    ? 'rounded-xl large-text indigo darken-3 white--text'
                    : 'rounded-xl large-text',attrs:{"disabled":_vm.loading,"ripple":"","tile":"","block":"","outlined":"","x-large":""},on:{"click":function($event){$event.stopPropagation();_vm.donationSelected = donation}}},[_vm._v(" "+_vm._s(("" + (donation.text)))+" ")])],1)}),1)],1)],1),(_vm.donationSelected && _vm.donationSelected.ident == 'amount_other')?_c('v-text-field',{staticClass:"mt-10 rounded-xl",attrs:{"oninput":"if(Number(this.value) > Number(this.max)) {this.value = this.max;} else if(Number(this.value) < Number(this.min)){ this.value = this.min;}","min":0,"max":9999,"error-messages":!_vm.donationSelected ||
          !_vm.donationSelected.amount ||
          _vm.donationSelected.amount < 5
            ? _vm.isHindi
              ? 'दान के लिए न्यूनतम राशि ₹5 है।'
              : 'Minimum amount for donation is ₹5.'
            : null,"outlined":"","label":_vm.isHindi
            ? '₹5 - ₹9999/- तक की कोई राशि। '
            : 'Any amount between ₹5 - ₹9999/-.',"prefix":"₹","x-large":"","type":"number"},on:{"blur":function () {
            if (
              !_vm.donationSelected ||
              !_vm.donationSelected.amount ||
              _vm.donationSelected.amount < 1
            ) {
              _vm.donationSelected.amount = 5;
            }
          }},model:{value:(_vm.donationSelected.amount),callback:function ($$v) {_vm.$set(_vm.donationSelected, "amount", $$v)},expression:"donationSelected.amount"}}):_vm._e(),_c('v-btn',{staticClass:"mt-4 indigo darken-4 rounded-xl",attrs:{"disabled":!_vm.donationSelected ||
          !_vm.donationSelected.amount ||
          _vm.donationSelected.amount < 5,"loading":_vm.loading,"outlined":"","dark":"","ripple":"","tile":"","block":"","x-large":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleFinalDonationClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.donationFinalBtnText)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-light d-md-none",domProps:{"innerHTML":_vm._s(_vm.isHindi ? _vm.preface.value_hi : _vm.preface.value)}},[_vm._v(" "+_vm._s(_vm.isHindi ? _vm.preface.value_hi : _vm.preface.value)+" ")]),_c('v-card-text',{staticClass:"title font-weight-light d-none d-md-flex",domProps:{"innerHTML":_vm._s(_vm.isHindi ? _vm.preface.value_hi : _vm.preface.value)}})],1)],1),_c('v-divider',{staticClass:"my-6"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }